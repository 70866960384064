import * as React from "react";
import { Link } from "gatsby";

import Widgets from "@components/Widgets";
import Seo from "@components/Seo";
import { Typography } from "@components/atoms";

const AboutUsPage: React.FC = () => {
  return (
    <Widgets>
      <Seo title="About Us" />
      <Typography variant="h1">Our Story</Typography>
      <Typography variant="body">
        Many years ago, when I was starting my journey as a software developer.
        I often found myself crawling different blog posts for answers to my
        problems.
      </Typography>
      <Typography variant="body">
        Now, I am at the point when I consumed a lot of knowledge. Through
        various tutorials, bootcamps, working for companies. Or building my own
        projects.
      </Typography>
      <Typography variant="body">
        I wanted to create a place where I can share my view on certain topics.
        Where I can provide solutions to problems I am facing. But most
        importantly. Where I can help you become better developer.
      </Typography>
      <Typography variant="body">
        That's why this platform was created. And that's why I'll do my best to
        help it grow and thrive. If you have any ideas for improvements. Feel
        free to <Link to="/contact">contact us</Link> using the dedicated form.
      </Typography>
    </Widgets>
  );
};

export default AboutUsPage;
